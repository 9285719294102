
import Vue from "vue";

export default Vue.extend({
	name: "DeliveryModelContent",
	props: {},
	components: {},
	data: () => ({}),
	async created() {},
	async mounted() {},
	computed: {},
	methods: {},
	watch: {}
});
