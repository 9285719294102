
import { isUndefined } from "lodash";
import Vue from "vue";
// @ts-ignore
import IconBase from "@/components/Commons/Icons/IconBase.vue";

export default Vue.extend({
	name: "CustomTab",
	props: {
		items: {
			type: Array,
			default: function () {
				return [];
			},
		},
		current_tab: {
			type: Number,
			default: 0,
		},
		vertical: {
			type: Boolean,
			default: false,
		},
		custom_class: {
			type: String,
			default: "v-tab--targeting",
		},
		slider_size: {
			type: Number,
			default: 4,
		},
		color: {
			type: String,
			default: "secondary",
		},
	},
	components: {
		IconBase,
	},
	data: () => ({}),
	created() {},
	async mounted() {},
	computed: {
		currentTab: {
			set(val) {
				this.$emit("update-selected-tab-index", val);
			},
			get() {
				return this.current_tab;
			},
		},
	},
	methods: {
		getUrl(item: { src: String }) {
			return item.src;
		},
		hasData(data: any) {
			return !isUndefined(data);
		},
		getIconType(key: any) {
			let icon = "";
			switch (key) {
				case 0:
					icon = "bid-modifier";
					break;

				case 1:
					icon = "delivery-modifier";
					break;

				case 2:
					icon = "bid-model";
					break;

				case 3:
					icon = "delivery-model";
					break;
			}

			return icon;
		},
	},
});
