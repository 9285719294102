import Vue, { PropType } from "vue";

import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
// @ts-ignore
import GridTable from "@/components/Header/Tables/GridTable.vue";
import { TypeLoading } from "@/interfaces/loading";
import { mapActions } from "vuex";
import { isEmpty, isNull, isString, orderBy } from "lodash";
import { ICompactEventInfo } from "@/interfaces/event";
import Filterable from "@/components/Header/Tables/Filterable.vue";
import { SortingOption } from "@/interfaces/paginated";
import ActiveFieldTable from "@/components/Header/Tables/ActiveFieldTable.vue";
import { getDefaultSortingOption } from "@/utils/filter-global";

export default Vue.extend({
  name: "EventListTable",
  props: {
    events: {
      type: Array as PropType<ICompactEventInfo[]>,
      default: () => ([])
    },
    type: {
      type: String as PropType<"line_item" | "campaign">,
      default: "campaign"
    }
  },
  components: {
    GridTable,
    Filterable,
    ActiveFieldTable,
  },
  data: () => ({
    deletable: null,
    options: getDefaultSortingOption(),
    filter: {
      external_id: "",
      name: ""
    },
    selected: [],
    showDeleteAll: false,
  }),
  async mounted() { },
  computed: {
    /**
     * Table headers
     * @returns Array of table headers
     */
    tableHeaders() {
      return [{
        text: this.$t('events.fields.id'),
        align: "left",
        sortable: false,
        filterable: true,
        value: "external_id",
        width: "20%"
      }, {
        text: this.$t('events.fields.name'),
        align: "left",
        sortable: false,
        filterable: true,
        value: "name"
      }, {
        text: this.$t('events.fields.status'),
        align: "left",
        sortable: false,
        filterable: true,
        value: "active",
        width: "20%"
      }, {
        text: "",
        align: "center",
        sortable: false,
        value: "actions",
        width: "5%"
      }]
    },

    /**
     * Filter event logic
     * @returns 
     */
    getEvents() {
      const events = orderBy(this.events, [this.options.sort], [this.options.order]);

      if (isString(this.filter)) {
        // @ts-ignore
        return events.filter(event => {
          if (typeof event !== "object" || isNull(event)) return true;

          return event[this.options.sort] && String(event[this.options.sort]).toLowerCase().indexOf(this.filter.toLowerCase()) !== -1;
        });
      }

      return events;
    },

    /**
     * Get dialog event message for deletion
     * @returns 
     */
    getMessage() {
      if (this.deletableEvent && !this.showDeleteAll) {
        return this.$t(`events.dialogs.confirm_delete_${this.type}`, {
          event_name: this.deletableEvent.name
        })
      }

      return this.$t('events.dialogs.confirm_delete_selected_events')
    },

    /**
     * Show the modal if the deletable item is not null
     * @returns boolean
     */
    showModal() {
      return !isNull(this.deletable) || this.showDeleteAll;
    },

    /**
     * Get info of the event to be deleted
     * @returns item to be deleted
     */
    deletableEvent() {
      return this.events.find(event => event.id == this.deletable);
    }
  },
  methods: {
    deleteAssociation(id: number) {
      this.deletable = id;
    },
    /**
     * Verify if the item is or not active
     * @param item Item to be evaluated
     * @returns boolean
     */
    isActive(item: ICompactEventInfo) {
      return item.active ?? true
    },
    /**
     * Send the delete item to the parent
     */
    handleYes() {
      if (this.showDeleteAll) {
        this.selected.forEach(event => {
          this.$emit("deleteItem", event.id);
        })
      } else {
        this.$emit("deleteItem", this.deletable)
      }

      this.deletable = null;
      this.showDeleteAll = false;
      this.selected = [];
    },
    /**
     * Close the modal if you don't want to delete
     */
    handleNo() {
      this.deletable = null;
      this.showDeleteAll = false;
    },

    /**
     * Refresh the filter after click or search
     * @param data 
     */
    selectedOption(data) {
      this.filter = data.filter;
      this.options = data.options;
    },

    /**
     * Reset filter
     */
    removeOption() {
      this.filter = undefined;
    },

    /**
     * 
     */
    handleDeleteSelected() {
      this.showDeleteAll = true;
    },

    /**
     * Redirect to current selected event on a new window
     * @param params event item
     */
    handleRedirectTo(event: any) {
      const routeData = this.$router.resolve({ name: "EventEdit", params: { id: event.id } });
      window.open(routeData.href, '_blank');
    }
  }
})