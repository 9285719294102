
import Vue from "vue";
import Alertize from "../../../../../components/Alertize.vue";
import { getError } from "../../../../../utils/resolveObjectArray";
import CardTextField from "./../../../../../components/Content/CardTextField.vue";
import CardAutocomplete from "./../../../../../components/Content/CardAutocomplete.vue";
import { isNull, isUndefined } from "lodash";
import {
	initModifier,
	initModifierCampaignAssociation,
	// @ts-ignore
} from "@/utils/initData";
import Overview from "./../../../Modifiers/overview.vue";
import { isRequired } from "../../../../../services/rule-services";
import { mapActions, mapGetters } from "vuex";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
// @ts-ignore
import { Paginated } from "../../../../interfaces/paginated";

// Constants
const ASSOCIATION_NULLABLE = -1;
const MODIFIER_TYPE_ID = 110;

export default Vue.extend({
	name: "BidModifierContent",
	props: {
		campaign: {
			type: Object,
			default: function () {
				return undefined;
			},
		},
		defaultIndexRoute: {
			type: String,
			default: function() {
				return "ModifiersIndex"
			}
		},
		line: {
			type: Object,
			default: function () {
				return undefined;
			},
		},
		disabledModifier: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		Alertize,
		CardTextField,
		CardAutocomplete,
		Overview,
	},
	data: () => ({
		valid: false,
		// CurrentModel Tab Selected
		currentModelSelected: 0,

		modifier: initModifier(),
		association: initModifierCampaignAssociation(),
		modifiersOptions: {},
		paginated: { page: 1, limit: 25 } as Paginated,
	}),
	async created() {},
	async mounted() {
		this.$nextTick(async () => {
			await this.onLoadResources();
		});
	},
	computed: {
		...mapGetters("profile", ["isRolReport"]),

		isReadOnly(){
			return this.isRolReport;
		},

		getErrors() {return this.$store.state.proccess.errors;},

		getAdvertisers(): any[] {return this.$store.state.advertiser.advertisers_list;},

		getModifierTypes(): any[] {return this.$store.state.modifier.modifier_types_list;},

		getModules(): any[] {return this.$store.state.modifier.modifier_modules_list;},

		getMatchingTypes(): any[]{return this.$store.state.modifier_options.matching_types;},

		getModifiers(): any[]{
			const list = [{
				value: this.$t("modifier.association.nullable"),
				id: ASSOCIATION_NULLABLE
			}];
			this.$store.state.modifier.modifiers_list.map( (e) => {
				list.push(e);
			});
			return list;
		},

		// Terms
		prepareTableHeaders() {
			return [
				{
					text: "Module",
					align: "start",
					sortable: false,
					filterable: false,
					value: "module_name",
				},
				{
					text: "Key",
					align: "start",
					sortable: false,
					filterable: false,
					value: "key",
				},
				{
					text: "Type",
					align: "start",
					sortable: false,
					filterable: false,
					value: "matching_type_name",
				},
				{
					text: "Value",
					align: "start",
					sortable: false,
					filterable: false,
					value: "value",
				},
				{
					text: "Modifier",
					align: "center",
					sortable: false,
					filterable: false,
					value: "modifier",
					width: "125px",
				},
				{
					text: "",
					align: "center",
					sortable: false,
					filterable: false,
					value: "actions",
				},
			];
		},

		initialiceModifier() {
			this.modifier = initModifier();
			this.modifier.modifier_type_id = MODIFIER_TYPE_ID;
			this.modifier.advertiser_id = this.getRelationshipEntity.advertiser_id;
			return this.modifier;
		},

		getModifier() {
			this.modifier.modifier_type_id = MODIFIER_TYPE_ID;
			this.modifier.advertiser_id = this.getRelationshipEntity.advertiser_id;
			return this.modifier;
		},

		getAssociation() {
			if ( !isUndefined(this.getRelationshipEntity.modifier_id) && !isNull(this.getRelationshipEntity.modifier_id) ) {
				this.association.modifier_id = this.getRelationshipEntity.modifier_id;
				this.association.max_bid = this.getRelationshipEntity.max_bid;
			}
			return this.association;
		},

		getRelationshipEntity() {
			if ( !isUndefined(this.campaign) ) {
				return this.campaign;
			} else if ( !isUndefined(this.line) ) {
				return this.line;
			}
			return undefined;
		},

		getRules() {
			return {
				isRequired,
			};
		},

		isMaxRequired(){
			return !isNull(this.getAssociation.modifier_id);
		}
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),

		...mapActions("modifier", ["getModifiersOptions"]),

		async onLoadResources(){
			await this.setLoadingData(TypeLoading.loading);
			await this.dispatchModifier();
			await this.dispatchModules();
			await this.dispatchMatchingTypes();
			await this.dispatchAdvertisers();
			await this.dispatchModifierTypes();
			this.modifiersOptions = await this.getModifiersOptions();
			await this.setLoadingData();
		},

		getError(index: string) {return getError(this.getErrors, index);},

		updateSelectedTabIndex(index: number) {
			this.currentModelSelected = index;
		},

		async dispatchModules() {
			return this.$store.dispatch("modifier/getModulesList", {
				root: true,
			});
		},

		async dispatchMatchingTypes() {
			return this.$store.dispatch("modifier_options/getMatchingTypes", {
				root: true,
			});
		},

		async dispatchAdvertisers() {
			return this.$store.dispatch("advertiser/list", {
				filters: { active: true },
				options: { sort: "name", order: "asc" }
			});
		},

		async dispatchModifierTypes() {
			return this.$store.dispatch("modifier/getModifierTypes", {
				root: true,
			});
		},

		async dispatchModifier() {
			return this.$store.dispatch("modifier/list", {
				filters: {
					modifier_type_extra: "bid_modifier",
					advertiser_id: this.getRelationshipEntity.advertiser_id
				},
				options: { sort: "name", order: "asc" },
				paginated: this.paginated
			});
		},

		handleSubmitModifier(params: any) {
			this.$emit("handle-submit-modifier", {
				...params,
				isBidModifier: true,
				executeDispatchFunction: () => {this.dispatchModifier();},
				executeClearFunction: () => {
					this.initialiceModifier;
				;},
			});
		},

		async handleAssociation(canContinue: boolean = false) {
			if ( !isUndefined(this.campaign) ) {
				this.association.campaign_id = this.getRelationshipEntity?.id;
			} else {
				this.association.line_item_id = this.getRelationshipEntity?.id;
			}
			if ( this.association.modifier_id !== ASSOCIATION_NULLABLE ) {
				if ( !(await this.validate()) ) {return;}
				this.association.modifier_type = "bid_modifier";
				this.association.max_bid = !isNull(this.association.max_bid) ? parseInt(this.association.max_bid) : null;
				this.$emit("handle-create-association", {
					entity: this.association,
					module: "BID_MODIFIER",
					continue: canContinue
				});
			} else{
				let entity = {};
				if ( !isUndefined(this.campaign) ) {
					entity = {campaign_id: this.getRelationshipEntity?.id,}
				} else {
					entity = {line_item_id: this.getRelationshipEntity?.id,}
				}
				this.$emit("handle-clear-association", {
					entity: {
						...entity,
						modifier_id: null,
						max_bid: null,
					},
					module: "BID_MODIFIER",
					continue: canContinue
				});
			}
		},

		handleCancel() {
			this.$emit("handle-cancel-modifiers");
		},

		async validate() {
			let form = this.$refs.form;
			const valid = await form.validate();
			return await valid;
		},

		updateMaxBit(){
			this.getAssociation.max_bid = null;
		}
	},
	watch: {
		"getAssociation.modifier_id"(){
			this.updateMaxBit();
		}
	}
});
